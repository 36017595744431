import React from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap";
import WhiteLogo from "../images/logo-white.svg";
import SearchIcon from "../images/search-white.svg";
import { translate } from "../utils"

const Header = () => {
  return (
    <Container>
      <Navbar>
        <Navbar.Brand href="/feed">
          <img alt="" src={WhiteLogo} />
        </Navbar.Brand>
        <Navbar>
          <Nav className="mr-auto">
            <Nav.Link href="#home">
              {translate('focus on benefits')}.
            </Nav.Link>
          </Nav>
          <Form inline className="w-25">
            <InputGroup>
              <FormControl
                placeholder=""
                aria-label=""
                aria-describedby="basic-addon1"
              />
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <Button>
                    <img alt="" src={SearchIcon} />
                  </Button>
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </Form>
        </Navbar>
      </Navbar>
    </Container>
  );
};

export default Header;
