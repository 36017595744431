import React from "react"
import PreHeader from "../components/preHeader"
import HeaderDrak from "../components/headerDrak"
import Footer from "../components/footer"
import { Container, Row, Col } from "react-bootstrap"
import backBtn from "../images/arrow-grren-outline.svg"


// import for translate
import {
  AUTH_TOKEN_KEY,
  COLOR_GREEN,
  COLOR_RED,
  DOMAIN_API,
  TYPE_ACTU,
  TYPE_ADVANTAGE,
  TYPE_ALL,
  URL_GET_USER_FULL,
  URL_GETALL_ADVANTAGES,
  URL_GETALL_ADVICEES, URL_GETALL_FOLDERS, URL_GETALL_NEWS, URL_GETALL_VIDEOS, URL_GETALL,
  URL_IS_AUTH, translate, URL_GET_MAINPARTNERSUPER_DATA, URL_GETALL_PARTNER_SUPER_FILTERED, getUserLanguage
} from "../utils"

const PrivacyTerms = () => {
  return (
    <div>
      <PreHeader />
      <div className="privacyTerms">
        <HeaderDrak />
        <div className="pageContent">
          <Container>
            <Row className="justify-content-center align-items-lg-end">
              <Col lg="8" className="order-lg-2">
                <h1 className="h0-title">
                  {translate('pt-title')}
                  Privacy <span className="d-block">terms</span>
                </h1>

                {/* paragraphe one start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title1')}</h2>
                <p>
                  {translate('pt-par1')}
                </p>
                {/* paragraphe one end */}

                {/* paragraphe two start */}
                <h2 className="h2-title pt-5 pb-3">{translate('pt-title2')}</h2>
                <p>
                  {translate('pt-par2')}
                </p>
                {/* paragraphe two end */}

                {/* paragraphe three start */}
                <h2 className="h2-title pt-5 pb-3">{translate('pt-title3')}</h2>
                <p>
                  {translate('pt-par3')}
                </p>
                <p>
                
               
                  <strong style={{textDecoration: 'underline'}}>{translate('De persoonsgegevens die u met ons deelt')}</strong><br/>
                  {translate('Wij verwerken de persoonlijke gegevens die u spontaan meedeelt. Deze communicatie kan telefonisch worden gedaan (bijvoorbeeld wanneer u de klantendienst belt om een vraag te stellen), schriftelijk (bijvoorbeeld wanneer u een bestelling voltooit om een pakket te reserveren), elektronisch (bijvoorbeeld wanneer u uw registratieformulier invult op de ENTER-BUSINESS sites) of wanneer u ons een sms of een e-mail stuurt om u te registreren voor een onlinewedstrijd of om een ENTER-BUSINESS-app te downloaden.Het gaat over de volgende gegevens:')}
                  <br/><br/>
                  {translate('Naam')}<br/>
                  {translate('Voornaam')}<br/>
                  {translate('Geslacht')}<br/>
                  {translate('Taal')}<br/>
                  {translate('Leeftijd (of geboortedatum, of veronderstelde gemiddelde leeftijd)')}<br/><br/>

                  {translate('Postadres')}<br/>
                  {translate('Telefoonnummer')}<br/>
                  {translate('Gsm-nummer')}<br/>
                  {translate('E-mailadres')}<br/>
                  {translate('Bedrijfsnaam')}<br/>
                  {translate('Postadres van uw bedrijf')}<br/>
                  {translate('Type activiteit van uw bedrijf')}<br/>
                  {translate('Uw functie')}<br/>
                  {translate('Internetcentra')}<br/>
                </p>

                <p>
                  <strong style={{textDecoration: 'underline'}}>{translate('Persoonsgegevens verkregen door derden')}</strong><br/>
                  {translate('We kunnen uw gegevens ontvangen van bedrijven die actief zijn in de bedrijfs- of ondernemerschapssector of gespecialiseerde ‘big data’ bedrijven, zoals bijvoorbeeld socio-demografische gegevens en informatie over uzelf om onze diensten beter aan te passen aan uw behoeften.')}
                  
                </p>
                <p>
                  {translate('Vervolgens kunnen we van de Vzw DNCM periodieke overzichten ontvangen van de mensen op de lijst "Bel me niet meer".')}
                </p>

                <p>
                  {translate('Het bedrijf dat ons uw gegevens verstrekt, kan ons ook vertellen wanneer u voor het laatst contact met hen hebt gehad (omdat u een product of dienst hebt gekocht of omdat u bijvoorbeeld deelnam aan een wedstrijd). Deze informatie stelt ons in staat om te verifiëren dat uw gegevens correct en actueel zijn (als u zojuist uw krant thuis hebt ontvangen, is de kans groot dat u op dit adres woont).')}
                </p>
                  

                <p>
                  <strong style={{textDecoration: 'underline'}}>{translate('Persoonsgegevens verkregen door enquêtes')}</strong><br/>
                  {translate('Als u onze consumptievragenlijsten (of die van onze partners) invult, zullen we over aanvullende gegevens over u beschikken, op basis van de antwoorden die u hebt gegeven.')}
                </p>

                <p>
                  <strong style={{textDecoration: 'underline'}}>{translate('Locatiegegevens verkregen door frequente locaties')}</strong><br/>
                  {translate('In sommige gevallen kunnen we de bedrijven die u vaak bezoekt identificeren, zoals kantoren, beurzen of vergaderzalen. Het verzamelen van deze gegevens helpt ons om de kwaliteit van ons distributienetwerk voor ENTER-BUSINESSkaarten te evalueren.')}
                </p>

                <p>
                  <strong style={{textDecoration: 'underline'}}>{translate('Andere gegevens')}</strong><br/>
                  {translate('In sommige gevallen kunnen we de bedrijven die u vaak bezoekt identificeren, zoals kantoren, beurzen of vergaderzalen. Het verzamelen van deze gegevens helpt ons om de kwaliteit van ons distributienetwerk voor ENTER-BUSINESSkaarten te evalueren.')}
                </p>
                <p>{translate('Cookies of andere soortgelijke technieken: wanneer u onze website www.enterbusiness.com bezoekt, kunnen wij informatie verzamelen uit onze cookies.')}</p>
                <p>{translate('We kunnen ook cookies plaatsen op e-mails die we u regelmatig toezenden in overeenstemming met onze verplichtingen. Deze verzamelde informatie helpt ons om u specifieke producten of diensten aan te bieden die u mogelijk interesseren en om uw interesse in de inhoud van verzonden e-mails te meten.')}</p>
                <p>{translate('Waar mogelijk houden we ook de data bij waarop al deze informatie aan ons wordt meegedeeld of wanneer er wijzigingen in zijn aangebracht.')}</p>
                {/* paragraphe three end */}

                {/* paragraphe four start */}
                <h2 className="h2-title pt-5 pb-3">{translate('pt-title4')}</h2>
                <p>
                  {translate('pt-par4')}
                </p>
                {/* paragraphe four end */}

                {/* paragraphe five start */}
                <p>
                  <h2><strong style={{textDecoration: 'underline'}}>{translate('pt-title5')}</strong><br/></h2>
                  {translate('pt-par5')}
                </p>
                <p>{translate('Deze registratie kan telefonisch worden gedaan (bijvoorbeeld wanneer u de klantendienst belt om een vraag te stellen), schriftelijk (bijvoorbeeld wanneer u een bestelling voltooit om een pakket te reserveren), elektronisch (bijvoorbeeld wanneer u uw registratieformulier invult op de websites ENTER-BUSINESS.COM) of wanneer u ons een sms of een e-mail stuurt om u te registreren voor een onlinewedstrijd of om een ENTER-BUSINESS-app te downloaden.')}</p>
                <p>{translate('ENTER-BUSINESS is ook partnerschappen aangegaan met commerciële bedrijven die hun de gegevens van hun klanten verstrekken.')}</p>
                <p>{translate('De gegevens kunnen ook worden aangevuld met een aantal statistische gegevens, zoals die van het Nationaal Instituut voor de Statistiek, bijvoorbeeld het gemiddelde inkomen van de buurt waar mensen wonen, het percentage tuinen, landeigenaren, enz.')}</p>
                <p>{translate('Al deze gegevens worden verzameld om ons "consumenten"-bestand te vormen dat op de markt wordt gebracht.')}</p>

                <p>
                  <strong style={{textDecoration: 'underline'}}>{translate('Kunnen we uw gegevens invullen met gegevens die we afleiden of vermoeden?')}</strong><br/>
                  {translate('Ja, we kunnen uw gegevens aanvullen met afgeleide of veronderstelde gegevens. Dus als u in een eengezinswoning woont en op hetzelfde adres, hebben we ook de gegevens van een andere persoon dan zullen we bijgevolg afleiden dat deze persoon deel uitmaakt van hetzelfde huishouden als u. Dit betekent niet dat we uitgaan van een familierelatie tussen jullie beiden, of dat we uw burgerlijke staat kennen. In werkelijkheid is deze informatie interessant vanuit een marketingperspectief. huishouden wordt verzonden.')}
                </p>
                {/* paragraphe five end */}
                
                {/* paragraphe six start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title6')}</h2>
                <p>{translate('pt-par6')}</p>
                {/* paragraphe six end */}

                {/* paragraphe seven start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title7')}</h2>
                <p>{translate('pt-par7')}</p>
                {/* paragraphe seven end */}

                {/* paragraphe eight start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title8')}</h2>
                <p>{translate('pt-par8')}</p>
                <h4><strong style={{textDecoration: 'underline'}}>{translate('pt-title8-underLineOne')}</strong><br/></h4>
                <p>{translate('pt-par8-underLineOne')}</p>
                {/* paragraphe eight end */}
                
                {/* paragraphe nine start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title9')}</h2>
                <h4><strong style={{textDecoration: 'underline'}}>{translate('pt-title9-underLineOne')}</strong><br/></h4>
                <p>{translate('pt-par9-underLineOne')}</p>

                <h4><strong style={{textDecoration: 'underline'}}>{translate('pt-title9-underLineTwo')}</strong><br/></h4>
                {/* Het bestaan van geautomatiseerde besluivorming (maybe to add separtly beacuase it's underline ) */}
                <p>{translate('pt-par9-underLineTwo')}</p>

                <h4><strong style={{textDecoration: 'underline'}}>{translate('pt-title9-underLineThree')}</strong><br/></h4>
                <p>{translate('pt-par9-underLineThree')}</p>

                <h4><strong style={{textDecoration: 'underline'}}>{translate('pt-title9-underLineFour')}</strong><br/></h4>
                <p>{translate('pt-par9-underLineFour')}</p>

                <h4><strong style={{textDecoration: 'underline'}}>{translate('pt-title9-underLineFive')}</strong><br/></h4>
                <p>{translate('pt-par9-underLineFive')}</p>

                <h4><strong style={{textDecoration: 'underline'}}>{translate('pt-title9-underLineSix')}</strong><br/></h4>
                <p>{translate('pt-par9-underLineSix')}</p>
                {/* paragraphe nine end */}

                {/* paragraphe ten start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title10')}</h2>
                <p>{translate('pt-par9-underLineTen')}</p>
                {/* paragraphe ten end */}

                {/* paragraphe eleven start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title11')}</h2>
                <p>{translate('pt-par9-underLineEleven')}</p>
                {/* paragraphe eleven end */}

                {/* paragraphe twelve start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title12')}</h2>
                <p>{translate('pt-par9-underLineTwelve')}</p>
                {/* paragraphe twelve end */}

                {/* paragraphe thirteen start */}
                <h2 className="h2-title pt-5 pb-3"> {translate('pt-title13')}</h2>
                <p>{translate('pt-par9-underLineThirteen')}</p>
                {/* paragraphe thirteen end */}
              </Col>
              <Col lg="2" className="text-center mt-5">
                <a href={'/feed'} className="btn BackBtn">
                  <img
                    className="img-fluid"
                    src={backBtn}
                    alt="Enter Business"
                  />
                  <span>Back</span>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default PrivacyTerms



